/* eslint-disable no-unused-vars */
import styles from "./CopyBtn.module.css";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import { IconSource } from "./Icon";
import jsPDF from "jspdf";

const ExportSourceToDoc = ({ content, name }) => {
  const [loading, setLoading] = useState(false);
  const exportToGoogleDoc = async () => {
    try {
      const fileName = name
        ? `${name.slice(0, 30).split(" ").join("-")}-source`
        : "source.pdf";

      setLoading(true);
      const doc = new jsPDF();
      const pageHeight = doc.internal.pageSize.getHeight(); // Get page height
      const pageWidth = doc.internal.pageSize.getWidth(); // Get page width
      const paddingLeft = 20; // Left padding
      const paddingRight = 20; // Right padding
      const maxLineWidth = pageWidth - paddingLeft - paddingRight; // Available width for text
      const lineHeight = 10; // Approximate height of one line of text
      const startY = 20; // Initial Y position
      let currentY = startY; // Current Y position tracker

      const lines = doc.splitTextToSize(content, maxLineWidth);
      lines.forEach((line) => {
        if (currentY + lineHeight > pageHeight) {
          doc.addPage(); // Add new page if text exceeds page height
          currentY = startY; // Reset Y position for new page
        }
        doc.text(line, paddingLeft, currentY);
        currentY += lineHeight; // Move down for the next line
      });

      doc.save(fileName);

      toast.success("Successfully");
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message);
      setLoading(false);
    }
  };
  if (!content || !content.length) {
    return <></>;
  }

  return (
    <Fragment>
      <div
        onClick={(e) => exportToGoogleDoc()}
        className={`${styles["copy-icon"]} ${styles["source-icon-one"]}`}
      >
        <IconSource className="size-5" />
      </div>
    </Fragment>
  );
};

export default ExportSourceToDoc;
