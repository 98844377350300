import styles from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { uiAction } from "../../store/ui-gemini";
import { themeIcon } from "../../asset";
import { useNavigate } from "react-router-dom";
import { chatAction } from "../../store/chat";
import { commonIcon } from "../../asset";
import BranchText from "../Ui/BranchText";
import { IconArrowRight } from "../Ui/Icon";
import { useLocation } from "react-router-dom";

const Header = ({ fullLogo, hideLogin }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewChat = useSelector((state) => state.chat.newChat);
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const userDetails = useSelector((state) => state.user.user);

  const hiddeUpgrade = () => {
    return location.pathname === "/subs";
  };
  const toggleSideBarHandler = () => {
    dispatch(uiAction.toggleSideBar());
  };

  const toggleAadvanceGeminiHandler = () => {
    dispatch(uiAction.toggleAdvanceShow());
  };

  const icon = themeIcon();

  const newChatHandler = () => {
    dispatch(chatAction.replaceChat({ chats: [] }));
    dispatch(chatAction.newChatHandler());
    dispatch(chatAction.chatHistoryIdHandler({ chatHistoryId: "" }));
    navigate("/home");
  };

  const userDetailsOpen = () => {
    dispatch(uiAction.toggleUserDetailsShow());
  };

  const loginHandler = () => {
    navigate("/login");
    // window.open(continueWithGoogleOauth(), "_self");
  };

  const loginSection = () => {
    if (hideLogin) {
      return <></>;
    }

    if (isLogin) {
      return (
        <>
          {!hiddeUpgrade() && (
            <button
              onClick={() => navigate("/subs")}
              className="hidden md:inline-flex justify-center items-center gap-1 text-white bg-[#0173B8] border border-gray-300 focus:outline-none hover:bg-gray-100 hover:text-black focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm md:px-5 md:py-2.5 p-1 me-2"
            >
              Upgrade to $19.95
              <IconArrowRight size="4" />
            </button>
          )}

          <div
            onClick={userDetailsOpen}
            className={`${styles["user"]} ${
              isUserDetails ? styles["clicked-user"] : ""
            }`}
          >
            {userDetails?.profileImg?.length > 0 ? (
              <img src={userDetails?.profileImg} alt=""></img>
            ) : (
              <img src={commonIcon.defaultAvatar} alt=""></img>
            )}
          </div>
        </>
      );
    }

    return (
      <div className={styles["login"]} onClick={loginHandler}>
        <p>Log in</p>
      </div>
    );
  };

  return (
    <div className={styles["header-main"]}>
      <div className={styles["left-section"]}>
        {!fullLogo && (
          <div className={styles["menu-icon"]} onClick={toggleSideBarHandler}>
            <img src={commonIcon.menuIcon} alt="menu icon"></img>
          </div>
        )}
        {fullLogo ? (
          <div className={styles["name"]} onClick={() => navigate("/home")}>
            <img
              src={commonIcon.fullLogo2}
              className="!w-40 !h-auto"
              alt="Gospel Truth AI"
              title="Home"
            ></img>
          </div>
        ) : (
          <div
            className="inline-flex gap-2 items-center cursor-pointer"
            onClick={toggleAadvanceGeminiHandler}
          >
            <BranchText hasChatType={true} />
            <img
              className="!size-3"
              src={icon.dropIconSmall}
              alt="drop down button"
            ></img>
          </div>
        )}
      </div>
      <div className={styles["right-section"]}>
        {isNewChat ? (
          <div
            onClick={newChatHandler}
            className={`${styles["plus-icon"]} ${styles["new-plus-icon"]}`}
          >
            <img
              src={icon.plusIcon}
              alt="plus icon"
              title="Click Here for New Chat"
            ></img>
          </div>
        ) : null}

        {loginSection()}
      </div>
    </div>
  );
};

export default Header;
