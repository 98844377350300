import React from "react";
import { IconClose } from "../Ui/Icon";

import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "../../store/ui-gemini";

const Tutorial = () => {
  const dispatch = useDispatch();
  const isShowTutorial = useSelector((state) => state.ui.isShowTutorial);

  const close = () => {
    dispatch(uiAction.toggleTutorial());
  };

  return (
    isShowTutorial && (
      <div
        onClick={close}
        className="modal flex overflow-y-auto overflow-x-hidden fixed inset-0 bg-gray-700 bg-opacity-75 z-[6] justify-center items-center w-full max-h-full"
      >
        <div
          className="relative p-4 w-full max-w-4xl max-h-full"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <div className="flex items-center justify-between border-b px-2 rounded-t dark:border-gray-600">
              {/* <h3 className="text-xl font-semibold text-black dark:text-white">
                Tutorial
              </h3> */}
              <button
                type="button"
                className="z-[7] text-gray-400 bg-transparent hover:bg-gray-200 hover:text-black rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                onClick={close}
              >
                <IconClose />
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <iframe
              //   width="100%"
              //   height="500"
              className="w-full md:h-[500px] h-[315px]"
              src="https://www.youtube.com/embed/HPJeyNbO1vQ?si=Lim9Y6iXy3IEWTjI&autoplay=1"
              title="Tutorial"
              allow="accelerometer; fullscreen ; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
              allowFullScreen
            ></iframe>
            {/* <div className="space-y-4">
              <div className="flex flex-col md:flex-row w-full">
                <video
                  className="w-full h-full"
                  id="tutorial-video"
                  controls
                  src="https://youtu.be/ember/HPJeyNbO1vQ"
                />
                
              </div>
            </div> */}
          </div>
        </div>
      </div>
    )
  );
};

export default Tutorial;
