export const TIME_INTERVAL_TYPEWRITER = 4; //miliseconds
export const CHAT_TYPE = {
  PRIVATE: "private",
  PUBLIC: "public",
  PUBLIC_O3: "public_O3",
  PRIVATE_O3: "private_O3",
  UPGRADE: "upgrade",
  DEEPSEEK: "deepseek",
  GPT_PRIVATE: "gpt_private",
  GPT_PUBLIC: "gpt_public",
};

export const ROLE = {
  ADMIN: "admin",
  USER: "user",
};
