import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../../store/user-action";
import { userAction } from "../../store/user";

import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import LoadingOverlay from "../Ui/LoadingOverlay";

const UserProfile = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user.user);
  const [loading, setLoading] = useState(false);

  const [userInput, setUserInput] = useState({
    name: userDetails.name,
    phone: userDetails.phone,
    email: userDetails.email,
    nickname: userDetails.nickname,
    biography: userDetails.biography,
  });

  const handleOnChange = (value, data, event, formattedValue) => {
    setUserInput((prevState) => ({
      ...prevState,
      phone: value,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setUserInput((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const callUpdateProfile = async () => {
      setLoading(true);
      try {
        await updateProfile(userInput);
        setTimeout(() => {
          setLoading(false);
        });
        dispatch(
          userAction.setUserData({
            userData: {
              nickname: userInput.nickname,
              name: userInput.name,
              phone: userInput.phone,
              biography: userInput.biography,
            },
          })
        );

        toast.success("Successfully");
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setLoading(false);
      }
    };
    callUpdateProfile();
  };

  return (
    <div>
      {loading && <LoadingOverlay />}
      <div className="space-y-6 flex justify-center items-center">
        <form onSubmit={onSubmitHandler}>
          <div className="mb-4 flex items-baseline">
            <span className="block mb-2 mr-3 text-sm font-medium text-gray-900 text-start">
              My Plan Usage:
            </span>
            <span>{userDetails?.currentLimit}</span>/
            <span>
              {userDetails?.planLimit?.count
                ? userDetails?.planLimit?.count
                : userDetails?.maxRateLimit}
            </span>
          </div>
          {userDetails?.buyMore?.quantity > 0 && (
            <div className="mb-4 flex items-baseline">
              <span className="block mb-2 mr-3 text-sm font-medium text-gray-900 text-start">
                My Addon Usage:
              </span>
              <span>{userDetails?.buyMore?.usedCount}</span>/
              <span>{userDetails?.buyMore?.quantity}</span>
            </div>
          )}
          <div className="w-full md:w-auto">
            <label
              htmlFor="nickname"
              className="block mb-2 text-sm font-medium text-gray-900 text-start"
            >
              Nickname
            </label>
            <input
              type="text"
              name="nickname"
              value={userInput?.nickname}
              onChange={handleChange}
              id="nickname"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 text-start"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              value={userInput?.email}
              id="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              disabled
            />
          </div>
          <div className="mb-4 flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0 md:space-x-4">
            <div className="w-full md:w-auto">
              <label
                htmlFor="fullname"
                className="block mb-2 text-sm font-medium text-gray-900 text-start"
              >
                Fullname
              </label>
              <input
                type="text"
                name="name"
                value={userInput?.name}
                onChange={handleChange}
                id="fullname"
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              />
            </div>
            <div className="w-full md:w-auto">
              <label
                htmlFor="fullname"
                className="block mb-2 text-sm font-medium text-gray-900  text-start"
              >
                Phone number
              </label>
              <PhoneInput
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: false,
                }}
                value={userInput?.phone}
                containerClass="w-full"
                buttonClass="border !border-gray-200 !rounded-l-md !z-50"
                inputClass="w-full !h-[42px] !rounded-md border !border-gray-200 focus:outline-none focus:ring-1 !focus:border-blue-900 peer"
                country={"us"}
                onChange={handleOnChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="biography"
              className="block mb-2 text-sm font-medium text-gray-900  text-start"
            >
              Biography
            </label>
            <textarea
              id="biography"
              name="biography"
              onChange={handleChange}
              value={userInput?.biography}
              rows="4"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            >
              {userInput?.biography}
            </textarea>
          </div>
          <button
            disabled={loading}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
