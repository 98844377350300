import { Helmet } from "react-helmet-async";
import React, { useEffect, useState, useMemo } from "react";
import { commonIcon, suggestPrompt } from "../asset";
import { shuffleArray } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IconArrowRight } from "../components/Ui/Icon";

const Share = () => {
  const navigate = useNavigate();
  const [suggest, setSuggest] = useState(suggestPrompt);

  const [index, setIndex] = useState(1);
  const [userInput, setUserInput] = useState("");
  const isLogin = useSelector((state) => state.auth.isLogin);

  useEffect(() => {
    const intervalId = setTimeout(() => {
      setIndex((pre) => pre + 1);
      if (index >= suggestPrompt.length) {
        setSuggest(shuffleArray(suggestPrompt));
        setIndex(1);
      }
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [index]);

  const userInputHandler = (e) => {
    setUserInput(e.target.value);
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    setUserInput("");

    return navigate("/home");
  };

  const loginSection = () => {
    if (isLogin) {
      return <></>;
    }

    return (
      <div className="inline-flex justify-center items-center gap-3">
        <button
          className="text-gray-900 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm md:px-5 md:py-2.5 p-1 me-2"
          onClick={() => navigate("/login")}
        >
          Log in
        </button>
        <button
          onClick={() => navigate("/signup")}
          className="inline-flex justify-center items-center gap-1 text-white bg-[#0173B8] border border-gray-300 focus:outline-none hover:bg-gray-100 hover:text-black focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm md:px-5 md:py-2.5 p-1 me-2"
        >
          Upgrade to $19.95
          <IconArrowRight size="4" />
        </button>
      </div>
    );
  };

  const meta = useMemo(() => {
    const title = "Gospel Truth AI";
    const description = "Gospel Truth AI";
    return (
      <Helmet>
        <meta name="robots" content="index,follow" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={window?.location?.href} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={window?.location?.href} />
        <meta property="og:site_name" content="Gospel Truth AI" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:url" content={window?.location?.href} />

        <meta itemProp="name" content={title} />
        <meta itemProp="description" content={description} />
      </Helmet>
    );
  }, []);

  return (
    <div className="bg-white h-screen">
      {meta}
      <div className="relative flex w-full h-16 bg-[#f3f4f6] ">
        <div className="md:px-5 flex justify-between items-center mx-auto h-16 w-full md:max-w-6xl">
          <img
            src={commonIcon.fullLogo2}
            className="h-10 cursor-pointer"
            alt="Logo"
          />
          <div>{loginSection()}</div>
        </div>
      </div>
      <div className="flex h-4/5 justify-center items-center">
        <div className="flex flex-col gap-6 min-w-[50%] px-5">
          <h1 className="text-3xl md:text-5xl text-center">
            Gospel Truth AI saves you up to twenty hours of research for every
            sermon and personal devotional you create
          </h1>
          <form onSubmit={onSubmitHandler} className="relative overflow-hidden">
            <input
              className={`w-full h-14 px-6 bg-[#F1F1F1] outline-none focus:ring-2 focus:ring-inset focus:ring-blue-600 rounded-full`}
              disabled={false}
              autoComplete="off"
              type="text"
              onChange={userInputHandler}
              placeholder="When you are inspired, you are more productive"
              value={userInput}
              name="prompt"
            ></input>
            <button
              disabled={false}
              type="submit"
              className={`absolute right-2 p-1 h-full w-16 inline-flex justify-center items-center opacity-20 z-[2] disabled:cursor-default enabled:opacity-100 enabled:hover:opacity-100`}
            >
              <img
                className="bg-[#F1F1F1] w-8 h-8 transform rotate-4"
                src={commonIcon.sendIcon}
                alt="send icon"
              ></img>
            </button>
            {/* {!userInput && (
              <div className="absolute z-[1] top-0 h-full w-full rounded-full px-[1.125rem] text-[#8F8F96] dark:text-gray-50 pointer-events-none overflow-hidden">
                <div
                  className="transform-gpu leading-[1.875rem] absolute md:left-7 mr-14 top-[0.75rem] easing-[cubic-bezier(0.215,0.61,0.355,1)] transition-transform duration-500"
                  style={{ transform: `translateY(${-(index * 30)}px)` }}
                >
                  {suggest.map((m, i) => {
                    return (
                      <span
                        key={i}
                        className={`transition-opacity duration-500 easing-[cubic-bezier(0.215,0.61,0.355,1)] whitespace-nowrap overflow-hidden text-ellipsis block max-w-[200px] sm:max-w-none md:max-w-none ${
                          index === i ? "opacity-1" : "opacity-0"
                        } `}
                      >
                        {m.sort}
                      </span>
                    );
                  })}
                </div>
              </div>
            )} */}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Share;
