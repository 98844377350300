import "./App.css";
import ChatSection from "./components/ChatSection/ChatSection";
import SettingSection from "./components/SettingSection/Setting";
import TutorialSection from "./components/Tutorial/TutorialPopup";
import Sidebar from "./components/Sidebar/Sidebar";
import { useSelector, useDispatch } from "react-redux";
import { uiAction } from "./store/ui-gemini";
import { useEffect } from "react";
import UserDetails from "./components/UserDetails/UserDetails";
import { getLanguage } from "./store/auth-action";

function App() {
  const dispatch = useDispatch();
  const settingsShow = useSelector((state) => state.ui.isSettingsShow);
  const isAdvanceGeminiPrompt = useSelector((state) => state.ui.isAdvanceShow);
  const isShowTutorial = useSelector((state) => state.ui.isShowTutorial);
  const showIntroUserPrompt = useSelector(
    (state) => state.ui.showIntroUserPrompt
  );

  const isDark = useSelector((state) => state.ui.isDark);
  const isUserDetails = useSelector((state) => state.ui.isUserDetailsShow);
  const isLogin = useSelector((state) => state.auth.isLogin);

  const settingHandler = () => {
    if (settingsShow === true) {
      dispatch(uiAction.toggleSettings());
    }
    if (isAdvanceGeminiPrompt === true) {
      dispatch(uiAction.toggleAdvanceShow());
    }

    if (isUserDetails === true) {
      dispatch(uiAction.toggleUserDetailsShow());
    }
  };
  useEffect(() => {
    dispatch(getLanguage());
  }, []);

  useEffect(() => {
    const getLocalTheme = localStorage.getItem("theme");
    const theme = getLocalTheme || "light";
    document.documentElement.setAttribute("data-theme", theme);
  }, [isDark]);

  useEffect(() => {
    setTimeout(() => {
      const isShowIntroAlready = localStorage.getItem("isTutorialShow") || "no";
      console.log(isShowIntroAlready);
      if (isShowIntroAlready === "no") {
        dispatch(uiAction.userIntroPromptHandler({ introPrompt: true }));
        dispatch(uiAction.toggleTutorial());

        localStorage.setItem("isTutorialShow", "yes");
      }
    }, 3000);
  }, [dispatch]);

  return (
    <div className="App">
      <Sidebar />
      <ChatSection />
      <SettingSection />
      <TutorialSection />

      {isUserDetails && isLogin && <UserDetails />}
      {isAdvanceGeminiPrompt && (
        <div onClick={settingHandler} className="bg-focus-dark"></div>
      )}
      {isUserDetails && isLogin && (
        <div onClick={settingHandler} className="bg-focus-dark"></div>
      )}
    </div>
  );
}

export default App;
