import styles from "./ScrollChat.module.css";
// import "./ScrollChatModule.css";
import { commonIcon } from "../../../asset";
import { useSelector, useDispatch } from "react-redux";
import React, { useRef, useEffect, Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getChat } from "../../../store/chat-action";
import ReplyByGemini from "./ReplyByGemini";
import ResponsePromptSection from "../ResponsePrompt/ResponsePrompt";
import CopyBtn from "../../Ui/CopyBtn";
import ShareBtn from "../../Ui/ShareBtn";
import ExportToDocBtn from "../../Ui/ExportToDocBtn";
import ExportSourceToDoc from "../../Ui/ExportSourceToDoc";
import { ROLE } from "../../../constants/index";

import TextToSpeech from "../../Ui/TextToSpeech";

const ScrollChat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { historyId } = useParams();
  const chatRef = useRef(null);
  const messages = useSelector((state) => state.chat.messages);
  const chatHistoryId = useSelector((state) => state.chat.chatHistoryId);
  const isLoader = useSelector((state) => state.chat.isLoader);
  const [scrollH, setScrollH] = useState(0);
  const isReplying = useSelector((state) => state.chat.isReplying);
  const userDetails = useSelector((state) => state.user.user);

  useEffect(() => {
    if (messages.length === 0 && !historyId) {
      navigate("/home");
    } else if (historyId && historyId !== chatHistoryId) {
      dispatch(getChat(historyId));
    } else {
      navigate(`/home/app/${chatHistoryId}`);
    }
  }, [dispatch, historyId, chatHistoryId, navigate, messages]);

  useEffect(() => {
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [messages, scrollH]);

  const chatSection = messages?.map((c, index) => (
    <Fragment key={c?._id + "" + index}>
      <div
        className={`${styles["single-chat"]} ${
          index === messages.length - 1 ? styles["last-single-chat"] : ""
        }`}
      >
        <div className="flex w-full flex-col gap-1 empty:hidden items-end rtl:items-start">
          <div className="text-right relative max-w-[70%] rounded-3xl bg-[#f4f4f4] px-5 py-2.5 dark:bg-token-main-surface-secondary">
            <p>{c.message?.user}</p>
          </div>
        </div>

        <div className={styles["gemini"]}>
          <img
            src={commonIcon.chatGeminiIcon}
            alt="avater icon"
            className={`border border-slate-200 rounded-full h-8 w-8 p-[4px] ${
              isLoader && !c.message?.gemini && index === messages.length - 1
                ? "animate-spin-slow"
                : ""
            }`}
          ></img>

          <ReplyByGemini
            gemini={c.message?.gemini}
            isNewChat={c.newChat}
            scrollHeight={(v) => setScrollH(v)}
          />
        </div>
        {!isReplying && (
          <>
            <div className="flex items-center relative">
              <div className="absolute right-0 flex justify-center items-center gap-2">
                <TextToSpeech messageId={c._id} />
                {userDetails?.role === ROLE.ADMIN && (
                  <ExportSourceToDoc
                    content={c.sources}
                    name={c.message?.user}
                  />
                )}
                <ExportToDocBtn
                  content={c.message?.gemini}
                  name={c.message?.user}
                />
                <ShareBtn
                  messageId={c._id}
                  historyId={chatHistoryId}
                  name={c.message?.user}
                  content={c.message?.gemini}
                />
                {c?.message?.gemini?.length > 0 && c.role !== "user" && (
                  <CopyBtn data={c?.message?.gemini} />
                )}
              </div>
            </div>
            {index === messages.length - 1 &&
              c.newChat &&
              c.message?.gemini && (
                <ResponsePromptSection chatType={c.chatType} />
              )}
          </>
        )}
      </div>
    </Fragment>
  ));

  return (
    <div className={styles["scroll-chat-main"]} ref={chatRef}>
      {chatSection}
      {/* {messageSection} */}
    </div>
  );
};

export default ScrollChat;
